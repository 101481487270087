import React, { useState, useEffect, useContext, useMemo } from 'react';
import Select from 'react-select';
import { Button, Card, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledDropdown } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams, useLocation, NavLink } from 'react-router-dom';
import { getFavoritesByConnectionId } from 'slices/favorites/thunk';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createCalculatedParameter, updateCalculatedParameter, deleteCalculatedParameter, getCalculatedParametersByZone } from 'slices/calculatedParameters/thunk';
import { getParameterValuesByConnection } from 'slices/parameterValues/thunk';
import { getParametersBackup } from 'slices/parametersBackup/thunk';
import { setFetchingFavorites, updateDashboardValues } from 'slices/favorites/reducer';
import { SocketContext } from 'context/SocketProvider';
import { setCalculatedParametersLoaded, setValuesOfMultiplesIps, updateCalculatedParameters } from 'slices/calculatedParameters/reducer';
import ModalStatusZone from './ModalStatusZone';
import { getStatusZone } from 'slices/statusZone/thunk';
import { getStatus } from 'slices/status/thunk';
import { setStatusZoneLoaded, updateStatusZoneValues } from 'slices/statusZone/reducer';
import { setFetchingParametersBackup, setisEnabled, setIsFetchingParameters, setLoadedParametersBackup, setZoneTotals, updateParameterBackupValue } from 'slices/parametersBackup/reducer';
import { setStatusLoaded } from 'slices/status/reducer';
import { toast } from 'react-toastify';
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';
import ModalViewStartupStatus from './ModalViewStatusStartup';
import { getStartupRegistrationByZone } from 'slices/startupRegistration/thunk';
import { units } from 'constants/units';
import { id } from 'date-fns/locale';
import rolesIds from 'constants/roles';
import { use } from 'i18next';
import { current } from '@reduxjs/toolkit';
import { set } from 'lodash';
import ModalHelp from 'Components/Common/Modals/ModalHelp';
import { setFetchingParameterValues } from 'slices/parameterValues/reducer';

interface CryptoWidget {
  id: number;
  icon: string;
  name: string;
  value: string;
  badge: string;
  badgeColor: string;
  percentage: string;
  decimal: string;
  unit: string;
  separator: string;
  in_graph?: boolean;
  register?: boolean;
  type_1?: string | null;
  connection_id_1?: number | null;
  value_1?: number | string | null;
  operator_1?: string | null;
  type_2?: string | null;
  connection_id_2?: number | null;
  value_2?: number | string | null;
  operator_2?: string | null;
  type_3?: string | null;
  connection_id_3?: number | null;
  value_3?: number | string | null;
  operator_3?: string | null;
  type_4?: string | null;
  connection_id_4?: number | null;
  value_4?: number | string | null;
  operator_4?: string | null;
  type_5?: string | null;
  connection_id_5?: number | null;
  value_5?: number | string | null;
  operator_5?: string | null;
  type_6?: string | null;
  connection_id_6?: number | null;
  value_6?: number | string | null;
  operator_6?: string | null;
  type_7?: string | null;
  connection_id_7?: number | null;
  value_7?: number | string | null;
  operator_7?: string | null;
  type_8?: string | null;
  connection_id_8?: number | null;
  value_8?: number | string | null;
  operator_8?: string | null;
  type_9?: string | null;
  connection_id_9?: number | null;
  value_9?: number | string | null;
  operator_9?: string | null;
  type_10?: string | null;
  connection_id_10?: number | null;
  value_10?: number | string | null;
  operator_10?: string | null;
}

interface FormValues {
  name: string;
  unit: string;
  in_graph: boolean;
  register: boolean;
  type_1: string | null;
  connection_id_1: number | null;
  value_1: number | string | null;
  operator_1: string | null;
  type_2: string | null;
  connection_id_2?: number | null;
  value_2?: number | string | null;
  operator_2?: string | null;
  type_3: string | null;
  connection_id_3: number | null;
  value_3?: number | string | null;
  operator_3?: string | null;
  type_4: string | null;
  connection_id_4?: number | null;
  value_4?: number | string | null;
  operator_4?: string | null;
  type_5: string | null;
  connection_id_5?: number | null;
  value_5?: number | string | null;
  operator_5?: string | null;
  type_6: string | null;
  connection_id_6?: number | null;
  value_6?: number | string | null;
  operator_6?: string | null;
  type_7: string | null;
  connection_id_7?: number | null;
  value_7?: number | string | null;
  operator_7?: string | null;
  type_8: string | null;
  connection_id_8?: number | null;
  value_8?: number | string | null;
  operator_8?: string | null;
  type_9: string | null;
  connection_id_9?: number | null;
  value_9?: number | string | null;
  operator_9?: string | null;
  type_10: string | null;
  connection_id_10?: number | null;
  value_10?: number | string | null;
  operator_10?: string | null;
  position?: number;
}

const customStyles = (marginBottom: number) => ({
  control: (provided: any) => ({
    ...provided,
    borderRadius: '50px',
    marginBottom: `${marginBottom}px`,
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
  }),
  clearIndicator: (base: any) => ({
    display: 'none',
  }),
});

interface GeneralDataProps {
  registers?: any;
  isRegistersLoaded?: boolean;
  zoneId?: number;
  zoneName?: string;
  disableEmittingValues?: boolean;
}


const GeneralData: React.FC<GeneralDataProps> = ({ registers, zoneId, zoneName, disableEmittingValues }) => {
  const dispatch: any = useDispatch();
  const [selectedItem, setSelectedItem] = useState<CryptoWidget | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pendingConnection, setPendingConnection] = useState<string | null>(null);
  const [loadingParameters, setLoadingParameters] = useState<boolean>(false);
  const [loadingFavorites, setLoadingFavorites] = useState<boolean>(false);
  const [selectedConnectionId, setSelectedConnectionId] = useState<number | null>(null);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [parameters, setParameters] = useState<any[]>([]);
  const [connections, setConnections] = useState<any[]>([]);
  const [currentZone, setCurrentZone] = useState<number | null>(null);
  const [prevLocation, setPrevLocation] = useState<string | null>(null);
  const [prevCalculatedParameters, setPrevCalculatedParameters] = useState<any[]>([]);
  const [prevCombinedRegisters, setPrevCombinedRegisters] = useState<any[]>([]);
  const [prevFilteredParametersBackup, setPrevFilteredParametersBackup] = useState<any[]>([]);
  const [prevStatusZone, setPrevStatusZone] = useState<any[]>([]);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isViewStatusModalOpen, setIsViewStatusModalOpen] = useState<boolean>(false);
  const [lastStartupRegistration, setLastStartupRegistration] = useState<any>(null);
  const [currentPosition, setCurrentPosition] = useState<number | undefined>(undefined);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const toggleHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);
  const [currentMessageHelp, setCurrentMessageHelp] = useState<string>('');
  const [currentTitleHelp, setCurrentTitleHelp] = useState<string>('');
  const socket = useContext(SocketContext);
  const param = useParams<{ id: string }>();
  const id = zoneId ? zoneId.toString() : param.id;
  const location = useLocation();

  const selectParametersBackupState = (state: any) => state.ParametersBackup;
  const selectInstallationsState = (state: any) => state.Installations;
  const selectFavoritesState = (state: any) => state.Favorites;
  const selectCalculatedParametersState = (state: any) => state.CalculatedParameters;
  const selectParameterValuesState = (state: any) => state.ParameterValues;
  const selectLayoutState = (state: any) => state.StatusZone;
  const selectStatusState = (state: any) => state.Status;
  const selectStartupRegistrationState = (state: any) => state.StartupRegistration;
  const selectDeviceState = (state: any) => state.Devices;

  const installationDataProperties = createSelector(
    selectInstallationsState,
    (state) => state
  );

  const parametersBackupProperties = createSelector(
    selectParametersBackupState,
    (state) => state
  );

  const favoritesProperties = createSelector(
    selectFavoritesState,
    (state) => state
  );

  const calculatedParametersProperties = createSelector(
    selectCalculatedParametersState,
    (state) => state
  );

  const parameterValuesProperties = createSelector(
    selectParameterValuesState,
    (state) => state
  );

  const statusZoneDataProperties = createSelector(
    selectLayoutState,
    (state: any) => state);

  const statusDataProperties = createSelector(
    selectStatusState,
    (state: any) => state);

  const startupRegistrationProperties = createSelector(
    selectStartupRegistrationState,
    (state: any) => state
  );

  const deviceDataProperties = createSelector(
    selectDeviceState,
    (state: any) => state
  );

  const profiledropdownData = createSelector(
    (state: any) => state.Profile.user,
    (user) => user
  );

  const user = useSelector(profiledropdownData);

  const { parametersBackup, loadedParametersBackup, filteredParameterBackup, currentParameterPath, isFetchingParametersBackup } = useSelector(parametersBackupProperties);
  const { installations } = useSelector(installationDataProperties);
  const { parameterValues, isFetchingParameterValues } = useSelector(parameterValuesProperties);
  const { favorites, isFetchingFavorites } = useSelector(favoritesProperties);
  const { loadedCalculatedParameters, valuesOfMultiplesIps } = useSelector(calculatedParametersProperties);
  const { loadedStatusZone } = useSelector(statusZoneDataProperties);
  const { status, loadedStatus } = useSelector(statusDataProperties);
  const { startupRegistration } = useSelector(startupRegistrationProperties);
  const { devices } = useSelector(deviceDataProperties);
  function useCalculatedParameters(zoneId: any, currentZone: any) {
    const effectiveZoneId = currentZone ? currentZone : zoneId;

    return useSelector(
      (state: any) => {
        const params = state.CalculatedParameters.calculatedParameters[effectiveZoneId];
        return params ? params : [];
      },
      (left, right) => JSON.stringify(left) === JSON.stringify(right)
    );
  }

  const calculatedParameters = useCalculatedParameters(id, currentZone);


  const zone = useSelector((state: any) => currentZone !== null ? state.StatusZone.statusZone[currentZone] : null);

  const statusZone = useMemo(() => {
    return Array.isArray(zone) ? zone : (zone ? [zone] : []);
  }, [zone, currentZone]);


  const selectStyles = {
    control: (base: any, state: any) => ({
      ...base,
      backgroundColor: 'transparent',
      borderRadius: '10px',
      borderColor: state.isFocused ? '#2684FF' : '#ced4da',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(38, 132, 255, 0.25)' : 'none',
      '&:hover': {
        borderColor: '#80bdff',
      },
      padding: '6px',
      fontSize: '14px',
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: '10px',
      zIndex: 9999,
    }),
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isSelected ? '#2684FF' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: state.isSelected ? '#2684FF' : '#f8f9fa',
      },
      padding: '10px',
      fontSize: '14px',
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999,
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#6c757d',
      fontSize: '14px',
    }),
    singleValue: (base: any) => ({
      ...base,
      color: '#495057',
      fontSize: '14px',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#495057',
    }),
    clearIndicator: (base: any) => ({
      ...base,
      color: '#495057',
    }),
    indicatorSeparator: (base: any) => ({
      display: 'none',
    }),
  };


  const initialValues: FormValues = {
    name: '',
    unit: '',
    in_graph: false,
    register: false,
    type_1: null,
    connection_id_1: null,
    value_1: null,
    operator_1: null,
    type_2: null,
    connection_id_2: null,
    value_2: null,
    operator_2: null,
    type_3: null,
    connection_id_3: null,
    value_3: null,
    operator_3: null,
    type_4: null,
    connection_id_4: null,
    value_4: null,
    operator_4: null,
    type_5: null,
    connection_id_5: null,
    value_5: null,
    operator_5: null,
    type_6: null,
    connection_id_6: null,
    value_6: null,
    operator_6: null,
    type_7: null,
    connection_id_7: null,
    value_7: null,
    operator_7: null,
    type_8: null,
    connection_id_8: null,
    value_8: null,
    operator_8: null,
    type_9: null,
    connection_id_9: null,
    value_9: null,
    operator_9: null,
    type_10: null,
    connection_id_10: null,
    value_10: null,
    operator_10: null,
    position: undefined,
  };

  const removeResultField = (data: any) => {
    return data.map((item: any) => {
      const { result, ...rest } = item;
      return rest;
    });
  };

  const removeValueField = (data: any) => {
    return data.map((item: any) => {
      const { value, ...rest } = item;
      return rest;
    });
  };



  useEffect(() => {

    const handleModbusValues = (data: any) => {


      console.log("datos", data)

      /*   const index = data.values.findIndex(
          (item: any) => item.connection_id === 74 && item.parameter_id === 73
        );
  
  
        if (index !== -1) {
          const originalValue = data.values[index].value;
          data.values[index].value = Math.trunc(data.values[index].value);
  
        } */


      const payload = {
        data: data,
        parametersBackup: parametersBackup,
        zoneId: Number(id)
      };

      dispatch(updateDashboardValues(payload));

      /*  dispatch(updateParameterBackupValue(data.values)) */
      dispatch(setValuesOfMultiplesIps(data && data.values));

      if (parametersBackup.length > 0) {

        dispatch(updateCalculatedParameters({
          parametersBackup: parametersBackup,
          favorites: favorites,
          data: data && data.values
        }));
      }

      dispatch(updateStatusZoneValues({
        parametersBackup: parametersBackup,
        data: data && data.values
      }));

      if (id === '17') {
        const result = data.values.find(
          (item: any) => item.connection_id === 74 && item.parameter_id === 10520
        );
        if (result) {
          dispatch(setisEnabled({ zoneId: id, isEnabled: result.value }));
        }

        const flowAcumulatedC = data.values.find(
          (item: any) => item.connection_id === 74 && item.parameter_id === 15060
        ) ? data.values.find(
          (item: any) => item.connection_id === 74 && item.parameter_id === 15060
        ).value : null;

        const flowAcumulatedRech = data.values.find(
          (item: any) => item.connection_id === 73 && item.parameter_id === 15200
        ) ? data.values.find(
          (item: any) => item.connection_id === 73 && item.parameter_id === 15200
        ).value : null;

        const flowAcumulatedB = data.values.find(
          (item: any) => item.connection_id === 72 && item.parameter_id === 15160
        ) ? data.values.find(
          (item: any) => item.connection_id === 72 && item.parameter_id === 15160
        ).value : null;

        const flowAcumulatedP = data.values.find(
          (item: any) => item.connection_id === 72 && item.parameter_id === 15200
        ) ? data.values.find(
          (item: any) => item.connection_id === 72 && item.parameter_id === 15200
        ).value : null;

        dispatch(setZoneTotals({
          zoneId: '17',
          totals: {
            flowAcumulatedC: flowAcumulatedC,
            flowAcumulatedRech: flowAcumulatedRech,
            flowAcumulatedB: flowAcumulatedB,
            flowAcumulatedP: flowAcumulatedP

          }
        }));

      }

      if (id === '18') {
        const result = data.values.find(
          (item: any) => item.connection_id === 75 && item.parameter_id === 15020
        );
        console.log("result", result)
        if (result) {
          console.log("resultado is Enabled", result.value);
          dispatch(setisEnabled({ zoneId: id, isEnabled: result.value }));
        }

        const flowAcumulatedC = data.values.find(
          (item: any) => item.connection_id === 75 && item.parameter_id === 15060
        ) ? data.values.find(
          (item: any) => item.connection_id === 75 && item.parameter_id === 15060
        ).value : null;

        const flowAcumulatedRech = data.values.find(
          (item: any) => item.connection_id === 76 && item.parameter_id === 15200
        ) ? data.values.find(
          (item: any) => item.connection_id === 76 && item.parameter_id === 15200
        ).value : null;

        const flowAcumulatedB = data.values.find(
          (item: any) => item.connection_id === 77 && item.parameter_id === 15160
        ) ? data.values.find(
          (item: any) => item.connection_id === 77 && item.parameter_id === 15160
        ).value : null;

        const flowAcumulatedP = data.values.find(
          (item: any) => item.connection_id === 77 && item.parameter_id === 15200
        ) ? data.values.find(
          (item: any) => item.connection_id === 77 && item.parameter_id === 15200
        ).value : null;

        dispatch(setZoneTotals({
          zoneId: '18',
          totals: {
            flowAcumulatedC: flowAcumulatedC,
            flowAcumulatedRech: flowAcumulatedRech,
            flowAcumulatedB: flowAcumulatedB,
            flowAcumulatedP: flowAcumulatedP

          }
        }));

      }

      if (id === '19') {
        const result = data.values.find(
          (item: any) => item.connection_id === 75 && item.parameter_id === 15020
        );
        if (result) {
          dispatch(setisEnabled({ zoneId: id, isEnabled: result.value }));
        }

        const flowAcumulatedElev = data.values.find(
          (item: any) => item.connection_id === 79 && item.parameter_id === 15040
        ) ? data.values.find(
          (item: any) => item.connection_id === 79 && item.parameter_id === 15040
        ).value : null;

        dispatch(setZoneTotals({
          zoneId: '19',
          totals: {
            flowAcumulatedElev: flowAcumulatedElev
          }
        }));

      }


    };


    if (socket) {

      socket.on('send_modbus_values_multiples_ips', handleModbusValues);
    }


    return () => {
      if (socket) {
        socket.off('send_modbus_values_multiples_ips', handleModbusValues);
      }
    };
  }, [socket, dispatch, parametersBackup, favorites, statusZone, status, valuesOfMultiplesIps, id]);

  const isDuplicate = (newItem: any, combinedRegisters: any) => {
    const ips = Object.keys(combinedRegisters);
    for (let ip of ips) {
      const existingItems = combinedRegisters[ip];
      if (Array.isArray(existingItems)) {
        if (existingItems.some((item: any) => JSON.stringify(item) === JSON.stringify(newItem))) {
          return true;
        }
      }
    }
    return false;
  };

  const removeValueFromRegisters = (registers: any) => {
    const newRegisters: any = {};
    for (const key in registers) {
      if (registers.hasOwnProperty(key)) {
        newRegisters[key] = registers[key].map((item: any) => {
          const { value, ...rest } = item;
          return rest;
        });
      }
    }
    return newRegisters;
  };


  /* useEffect(() => {
    const handleModbusValues = (data: any) => {
      if (data && data.values) {
        dispatch(updateDashboardValues(data));
      }
    };

    if (loadedCalculatedParameters && loadedParametersBackup && loadedStatusZone && loadedStatus && !disableEmittingValues) {
      const combinedRegisters = JSON.parse(JSON.stringify(registers ? registers : {}));
      const cleanedCombinedRegisters = removeValueFromRegisters(combinedRegisters);


      const updateMinAndMaxInRegisters = (registers: any, parametersBackup: any) => {
        Object.keys(registers).forEach((ip) => {
          registers[ip].forEach((register: any) => {
            const connectionId = register.connection_id;
            const parameterId = register.parameter_id;


            const backupParameters = parametersBackup[connectionId]?.parameters || [];
            const matchingParameter = backupParameters.find(
              (param: any) => Number(param.parameter_id) === parameterId
            );

            if (matchingParameter) {
              register.min = matchingParameter.min || register.min;
              register.max = matchingParameter.max || register.max;
            }
          });
        });
      };

      updateMinAndMaxInRegisters(cleanedCombinedRegisters, parametersBackup);

      calculatedParameters.forEach((calcParam: any) => {
        for (let i = 1; i <= 5; i++) {
          const typeKey = `type_${i}`;
          const connectionIdKey = `connection_id_${i}`;
          const valueKey = `value_${i}`;

          if (calcParam[typeKey] === 'Parameter' && calcParam[connectionIdKey] !== null && calcParam[valueKey] !== null) {
            const connectionId = calcParam[connectionIdKey];
            const connectionBackup = parametersBackup[connectionId];

            if (connectionBackup) {
              const ip = connectionBackup.connection_ip;
              if (!cleanedCombinedRegisters[ip]) {
                cleanedCombinedRegisters[ip] = [];
              }

              const parameter = connectionBackup?.parameters?.find((param: any) => param.id == Number(calcParam[valueKey]));

              if (parameter) {
                const newItem = {
                  bit_parameter_id: parameter.bit_parameter_id || null,
                  connection_id: connectionId,
                  idmap_id: parameter.idmap_id || null,
                  is_active: true,
                  max: parameter.max || 'N/A',
                  max_scale: parameter.max_scale || null,
                  min: parameter.min || 'N/A',
                  min_scale: parameter.min_scale || null,
                  parameter_id: Number(parameter.parameter_id),
                  unit: parameter.unit || null,
                  value: ''
                };
                if (!isDuplicate(newItem, cleanedCombinedRegisters)) {
                  cleanedCombinedRegisters[ip].push(newItem);
                }
              }
            }
          } else if (calcParam[typeKey] === 'Favorite' && calcParam[connectionIdKey] !== null && calcParam[valueKey] !== null) {
            const connectionId = calcParam[connectionIdKey];
            const favorite = favorites[connectionId]?.find((fav: any) => fav.id === Number(calcParam[valueKey]));

            if (favorite) {
              const ip = parametersBackup[connectionId]?.connection_ip;
              if (!cleanedCombinedRegisters[ip]) {
                cleanedCombinedRegisters[ip] = [];
              }
              const parameter = parametersBackup[connectionId]?.parameters?.find((param: any) => param.id == favorite.parameter_id);
              if (parameter) {
                const newItem = {
                  bit_parameter_id: favorite.bit_parameter_id || null,
                  connection_id: connectionId,
                  idmap_id: parameter.idmap_id || null,
                  is_active: true,
                  max: parameter.max || 'N/A',
                  max_scale: favorite.max_scale !== undefined && favorite.max_scale !== null ? Number(favorite.max_scale) : null,
                  min: parameter.min || 'N/A',
                  min_scale: favorite.min_scale !== undefined && favorite.min_scale !== null ? favorite.min_scale : null,
                  parameter_id: parameter.parameter_id,
                  unit: favorite.unit || null,
                  value: ''
                };
                if (!isDuplicate(newItem, cleanedCombinedRegisters)) {
                  cleanedCombinedRegisters[ip].push(newItem);
                }
              }
            }
          }
        }
      });

      statusZone.forEach((statusZoneItem: any) => {
        const connectionId = statusZoneItem.connection_id;
        const parameterId = statusZoneItem.parameter_id;
        const connectionBackup = parametersBackup[connectionId];

        if (connectionBackup) {
          const ip = connectionBackup.connection_ip;
          if (!cleanedCombinedRegisters[ip]) {
            cleanedCombinedRegisters[ip] = [];
          }

          const parameter = connectionBackup?.status?.find((param: any) => param.id === parameterId);


          if (parameter) {
            const newItem = {
              bit_parameter_id: parameter.bit_parameter_id || null,
              connection_id: connectionId,
              idmap_id: parameter.idmap_id || null,
              is_active: true,
              max: parameter.max || 'N/A',
              max_scale: parameter.max_scale || null,
              min: parameter.min || 'N/A',
              min_scale: parameter.min_scale || null,
              parameter_id: Number(parameter.parameter_id),
              unit: parameter.unit || null,
              value: ''
            };
            if (!isDuplicate(newItem, cleanedCombinedRegisters)) {
              cleanedCombinedRegisters[ip].push(newItem);
            }
          }
        }
      });

      const isRouteDiferent = prevLocation !== location.pathname;
      const isCalculatedParametersDiferent = prevLocation == location.pathname && calculatedParameters && calculatedParameters.length != 0;
      const isStatusZoneDiferent = prevLocation == location.pathname && statusZone && statusZone.length != 0;
      const isRegistersDiferent = JSON.stringify(cleanedCombinedRegisters) != JSON.stringify(prevCombinedRegisters);
      const isFilteredParametersBackupDiferent = JSON.stringify(filteredParameterBackup) != JSON.stringify(prevFilteredParametersBackup);


      const shouldEmitValues =
        socket &&
        loadedParametersBackup &&
        loadedCalculatedParameters &&
        (isRouteDiferent || isCalculatedParametersDiferent || isStatusZoneDiferent || isRegistersDiferent || isFilteredParametersBackupDiferent);





      if (shouldEmitValues) {


        const authUserString = sessionStorage.getItem("authUser");

        if (authUserString) {

          const authUser = JSON.parse(authUserString);
          console.log("Emitiendo valores de modbus para múltiples hilos:", cleanedCombinedRegisters);
            socket.emit('start_modbus_values_multiples_ips', { ip_registers: cleanedCombinedRegisters, token: authUser?.token }); 
          setPrevCalculatedParameters(calculatedParameters);
          setPrevStatusZone(statusZone);
          setPrevCombinedRegisters(cleanedCombinedRegisters);
          setPrevFilteredParametersBackup(filteredParameterBackup)

        }
      }
    }
  }, [socket, parametersBackup, calculatedParameters, prevLocation, statusZone, registers, loadedParametersBackup]); */

  useEffect(() => {
    setPrevLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleLocationChange = () => {
      if (socket) {
        socket.emit('location_change', { pathname: location.pathname });
      }
    };

    handleLocationChange();

    return () => {
    };
  }, [location.pathname, socket]);

  useEffect(() => {
    const isZonaRoute =
      location.pathname.includes('/dashboard/zona/') ||
      location.pathname.includes('/registros/alarmas-fallos/') ||
      location.pathname.includes('/registros/zona/') ||
      location.pathname.includes('/dashboard/instalacion/') ||
      location.pathname.includes('/sinoptico/zona/');
    if (installations && installations.length > 0 && Array.isArray(installations)) {
      if (isZonaRoute) {
        const matchedZones = installations.flatMap((installation: any) =>
          installation.zones.filter((zone: any) => zone.id == Number(id))
        );
        if (matchedZones.length > 0) {
          setConnections(matchedZones[0].connections);
          setCurrentZone(matchedZones[0].id);
        }
      } else {
        const connectionId = Number(id);

        const matchedZone = installations
          .flatMap((installation: any) => installation.zones)
          .find((zone: any) =>
            zone.connections.some((connection: any) => connection.id === connectionId)
          );

        if (matchedZone) {
          const allConnectionsInZone = matchedZone.connections;

          setConnections(allConnectionsInZone);
          setCurrentZone(matchedZone.id);
        } else {
          setConnections([]);
          setCurrentZone(null);
        }
      }
    }

    setLoadingPage(false);
  }, [id, location.pathname, installations]);



  useEffect(() => {
    if (pendingConnection && parametersBackup[pendingConnection]) {
      const selectedConnection = parametersBackup[pendingConnection];
      const filteredParameters = (selectedConnection?.parameters || []).filter((param: any) => param.parameter_type === 'Visualization');
      setParameters(filteredParameters);
      setLoadingParameters(false);
    }
  }, [parametersBackup, pendingConnection]);

  /*   useEffect(() => {
      if (selectedConnectionId !== null) {
        setLoadingFavorites(true);
        dispatch(getFavoritesByConnectionId(selectedConnectionId)).then(() => {
          setLoadingFavorites(false);
        });
      }
    }, [selectedConnectionId, dispatch]); */

  /*  useEffect(() => {
     const fetchData = async (connectionId: number) => {
       if ((!parameterValues || !parameterValues[connectionId]) && !isFetchingParametersBackup[connectionId]) {
         await dispatch(getParameterValuesByConnection(connectionId));
       }
 
       if ((!parametersBackup || !parametersBackup[connectionId]) && parameterValues[connectionId] && !isFetchingParametersBackup[connectionId]) {
         dispatch(setFetchingParametersBackup({ connection_id: connectionId, isFetching: true }));
         await dispatch(getParametersBackup({ connectionId, parameterValues: parameterValues[connectionId] }));
       }
     };
 
     const fetchAllData = async () => {
       await Promise.all(connections.map(connection => fetchData(connection.id)));
       dispatch(setIsFetchingParameters(false));
       dispatch(setLoadedParametersBackup(true));
 
     };
 
     if (connections && Array.isArray(connections) && connections.length > 0) {
       fetchAllData();
     }
   }, [dispatch, connections, parameterValues, parametersBackup]); */

  // useEffect para parameterValues
  useEffect(() => {
    let isMounted = true;

    const fetchParameterValues = async (connectionId: any) => {
      if (!parameterValues[connectionId] && !isFetchingParameterValues[connectionId]) {
        try {
          dispatch(setFetchingParameterValues({ connection_id: connectionId, isFetching: true }));
          await dispatch(getParameterValuesByConnection(connectionId));
        } catch (error) {
          console.error("Error fetching parameter values for connection", connectionId, error);
        } finally {
          if (isMounted) {
            dispatch(setFetchingParameterValues({ connection_id: connectionId, isFetching: false }));
          }
        }
      }
    };

    const fetchAllParameterValues = async () => {
      for (const connection of connections) {
        await fetchParameterValues(connection.id);
      }
    };

    if (connections?.length > 0) {
      fetchAllParameterValues();
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, connections]);

  // useEffect para parametersBackup
  useEffect(() => {
    let isMounted = true;

    const fetchParametersBackup = async (connectionId: any) => {
      if (!parametersBackup[connectionId] && parameterValues[connectionId] && !isFetchingParametersBackup[connectionId]) {
        try {
          dispatch(setFetchingParametersBackup({ connection_id: connectionId, isFetching: true }));
          await dispatch(getParametersBackup({ connectionId, parameterValues: parameterValues[connectionId] }));
        } catch (error) {
          console.error("Error fetching parameters backup for connection", connectionId, error);
        } finally {
          if (isMounted) {
            dispatch(setFetchingParametersBackup({ connection_id: connectionId, isFetching: false }));
          }
        }
      }
    };

    const fetchAllParametersBackup = async () => {
      for (const connection of connections) {
        await fetchParametersBackup(connection.id);
      }
      if (isMounted) {
        const allLoaded = connections.every(
          (connection) => parametersBackup[connection.id]
        );
        if (allLoaded) {
          dispatch(setLoadedParametersBackup(true));
        }
      }
    };

    if (connections?.length > 0 && parameterValues) {
      fetchAllParametersBackup();
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, connections, parameterValues, parametersBackup, isFetchingParametersBackup]);


  useEffect(() => {
    let isMounted = true;  // Este flag controla la ejecución asincrónica dentro de un efecto.

    const fetchFavorites = async (connectionId: any) => {
      // Solo se intenta recuperar favoritos si aún no se han cargado y si los datos necesarios están disponibles y completos.
      if (!favorites[connectionId] && parametersBackup[connectionId] && parameterValues[connectionId] && !isFetchingFavorites[connectionId]) {
        try {
          dispatch(setFetchingFavorites({ connection_id: connectionId, isFetching: true }));
          await dispatch(getFavoritesByConnectionId(connectionId));
        } catch (error) {
          console.error("Error fetching favorites for connection", connectionId, error);
        } finally {
          if (isMounted) {
            dispatch(setFetchingFavorites({ connection_id: connectionId, isFetching: false }));
          }
        }
      }
    };

    const fetchAllFavorites = async () => {
      await Promise.all(connections.map(connection => fetchFavorites(connection.id)));
    };

    // Ejecuta fetchAllFavorites solo si todas las conexiones tienen sus datos necesarios cargados.
    if (connections.length > 0 && connections.every(conn => parametersBackup[conn.id] && parameterValues[conn.id])) {
      fetchAllFavorites();
    }

    return () => {
      isMounted = false;  // Asegura que no se establezcan estados si el componente se ha desmontado.
    };
  }, [dispatch, connections, parameterValues, parametersBackup, favorites, isFetchingFavorites]);





  /*   useEffect(() => {
      if (connections && Array.isArray(connections) && connections.length > 0) {
        connections.forEach(connection => {
          dispatch(getFavoritesByConnectionId(connection.id));
        });
      }
    }, [connections, dispatch]);
   */
  useEffect(() => {

    if (currentZone !== null && calculatedParameters.length == 0) {
      dispatch(getCalculatedParametersByZone(currentZone)).then(() => {
        dispatch(setCalculatedParametersLoaded(true))
      });
      dispatch(getStatus(currentZone)).then(() => {
        dispatch(setStatusLoaded(true));
      });

      dispatch(getStatusZone(currentZone)).then(() => {
        dispatch(setStatusZoneLoaded(true));
      });


      dispatch(getStartupRegistrationByZone(currentZone));
    }
  }, [dispatch, currentZone]);



  useEffect(() => {
    if (startupRegistration && startupRegistration.length > 0) {
      setLastStartupRegistration(startupRegistration[startupRegistration.length - 1]);
    }
  }, [startupRegistration]);


  const handleCardClick = async (item: any | null, index: number) => {
    if (connections.length === 0 || user.role_id == rolesIds.operator) {
      return;
    }

    setSelectedItem(item);
    setCurrentPosition(index + 1);
    if (item) {
      setInitialFormValues({
        name: item.name || '',
        unit: item.unit || '',
        in_graph: item.in_graph || false,
        register: item.register || false,
        type_1: item.type_1 || null,
        connection_id_1: item.connection_id_1 || null,
        value_1: item.value_1 || null,
        operator_1: item.operator_1 || null,
        type_2: item.type_2 || null,
        connection_id_2: item.connection_id_2 || null,
        value_2: item.value_2 || null,
        operator_2: item.operator_2 || null,
        type_3: item.type_3 || null,
        connection_id_3: item.connection_id_3 || null,
        value_3: item.value_3 || null,
        operator_3: item.operator_3 || null,
        type_4: item.type_4 || null,
        connection_id_4: item.connection_id_4 || null,
        value_4: item.value_4 || null,
        operator_4: item.operator_4 || null,
        type_5: item.type_5 || null,
        connection_id_5: item.connection_id_5 || null,
        value_5: item.value_5 || null,
        operator_5: item.operator_5 || null,
        type_6: item.type_6 || null,
        connection_id_6: item.connection_id_6 || null,
        value_6: item.value_6 || null,
        operator_6: item.operator_6 || null,
        type_7: item.type_7 || null,
        connection_id_7: item.connection_id_7 || null,
        value_7: item.value_7 || null,
        operator_7: item.operator_7 || null,
        type_8: item.type_8 || null,
        connection_id_8: item.connection_id_8 || null,
        value_8: item.value_8 || null,
        operator_8: item.operator_8 || null,
        type_9: item.type_9 || null,
        connection_id_9: item.connection_id_9 || null,
        value_9: item.value_9 || null,
        operator_9: item.operator_9 || null,
        type_10: item.type_10 || null,
        connection_id_10: item.connection_id_10 || null,
        value_10: item.value_10 || null,
        operator_10: item.operator_10 || null,
        position: index + 1
      });

      for (let i = 1; i <= 10; i++) {
        if (item[`type_${i}`] === 'Parameter' && item[`connection_id_${i}`]) {
          const connectionId = item[`connection_id_${i}`];
          setPendingConnection(connectionId);
          if (!parametersBackup[connectionId]) {
            setLoadingParameters(true);
            /*  await dispatch(getParametersBackup({ connectionId, parameterValues: parameterValues[connectionId] })); */
            setLoadingParameters(false);
          } else {
            const selectedConnection = parametersBackup[connectionId];
            const filteredParameters = (selectedConnection?.parameters || []).filter((param: any) => param.parameter_type === 'Visualization');
            setParameters(filteredParameters);
          }
        }
      }
    } else {
      setInitialFormValues({
        ...initialValues,
        position: index + 1
      });
    }
    setIsModalOpen(true);
  };

  const handleStatusCardClick = () => {
    setIsStatusModalOpen(true);
  };

  const handleViewStatusCardClick = () => {
    setIsViewStatusModalOpen(true);
  };

  useEffect(() => {
    return () => {
      if (socket) {
        /*    socket.emit('stop_modbus_values_multiples_ips'); */
      }
    };
  }, [])

  const [initialFormValues, setInitialFormValues] = useState<FormValues>(initialValues);

  const handleConfirm = (values: FormValues) => {
    const mappedValues = mapOperations(values);

    const action = selectedItem
      ? updateCalculatedParameter({ ...mappedValues, id: selectedItem.id })
      : createCalculatedParameter(mappedValues);

    dispatch(action)
      .then(() => {
        toggleModal();
        toast.success('Parámetro calculado modificado correctamente');
      })
      .catch((error: any) => {
        toast.error(`Error: ${error.message}`);
      });
  };

  const handleDelete = () => {

    if (selectedItem) {
      dispatch(deleteCalculatedParameter({ id: selectedItem.id, zoneID: currentZone }))
        .then(() => {
          toast.success('Parámetro calculado eliminado correctamente');
        })
        .catch((error: any) => {
          toast.error(`Error al eliminar el parámetro calculado: ${error.message}`);
        });
    }
    setIsDeleteModalOpen(false);
    toggleModal();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
  });

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const toggleStatusModal = () => setIsStatusModalOpen(!isStatusModalOpen);
  const toggleViewStatusModal = () => setIsViewStatusModalOpen(!isViewStatusModalOpen);

  const mapOperations = (values: FormValues) => {
    const mappedValues: any = {
      name: values.name,
      unit: values.unit,
      in_graph: values.in_graph,
      register: values.register,
      installation_zone_id: currentZone,
      position: values.position
    };

    for (let i = 1; i <= 10; i++) {
      mappedValues[`type_${i}`] = values[`type_${i}` as keyof FormValues];
      mappedValues[`connection_id_${i}`] = values[`connection_id_${i}` as keyof FormValues];
      mappedValues[`value_${i}`] = values[`value_${i}` as keyof FormValues];
      mappedValues[`operator_${i}`] = values[`operator_${i}` as keyof FormValues] === 'ninguno' ? null : values[`operator_${i}` as keyof FormValues];
    }
    return mappedValues;
  };

  const optionTypeOptions = [
    { value: 'Constant', label: 'Constante' },
    { value: 'Parameter', label: 'Parámetro' },
    { value: 'Favorite', label: 'Favorito' },
    { value: 'calculatedParameter', label: 'Variable Calculada' }
  ];

  const operatorOptions = [
    { value: '+', label: '+' },
    { value: '-', label: '-' },
    { value: '*', label: '*' },
    { value: '/', label: '/' },
    { value: 'ninguno', label: 'Ninguno' }
  ];

  const resetFields = (setFieldValue: any, index: number) => {
    for (let i = index + 1; i <= 10; i++) {
      setFieldValue(`type_${i}`, null);
      setFieldValue(`connection_id_${i}`, null);
      setFieldValue(`value_${i}`, null);
      setFieldValue(`operator_${i}`, null);
    }
  };

  const renderStatusZone = () => {
    if (!Array.isArray(statusZone) || statusZone.length === 0) {
      return (
        <div className="d-flex flex-column align-items-center">
          <p className="text-muted mt-2 text-center">No hay estados disponibles</p>
        </div>
      );
    }


    const reversedStatusZone = [...statusZone].reverse();
    let message = null;

    for (const statusZoneItem of reversedStatusZone) {
      if (statusZoneItem.value !== undefined && (statusZoneItem.message_activated || statusZoneItem.message_deactivated)) {
        if (statusZoneItem.message_activated && statusZoneItem.message_deactivated) {
          message = statusZoneItem.value ? statusZoneItem.message_activated : statusZoneItem.message_deactivated;
        } else if (statusZoneItem.message_activated && !statusZoneItem.message_deactivated) {
          if (statusZoneItem.value) {
            message = statusZoneItem.message_activated;
          } else {
            const previousStatusWithDeactivatedMessage = reversedStatusZone.find((item) =>
              item.message_deactivated && item.message_deactivated !== "" && item.value === false
            );
            message = previousStatusWithDeactivatedMessage ? previousStatusWithDeactivatedMessage.message_deactivated : null;
          }
        }

        if (message && message !== "") {
          break;
        }
      }
    }

    /*   if (!message || message === "") {
        return (
          <div className="d-flex flex-column align-items-center">
            <Spinner size="sm" color="primary" />
          </div>
        );
      } */

    return (
      <div>
        <h5>{message}</h5>
      </div>
    );
  };






  return (
    <React.Fragment>
      {loadingPage ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12 col-lg-2 mb-3">
            <Card
              className=' mb-3 mt-0 h-100 px-4 '
              style={{
                transition: 'transform 0.3s ease',
                cursor: 'pointer',
                minHeight: '45px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'translateY(5px)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'translateY(0)'}
            >
              <UncontrolledDropdown direction='start' style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <DropdownToggle className="text-reset" tag="a" role="button">
                  <span className="text-muted fs-18"><i className="mdi mdi-dots-horizontal"></i></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-end">
                  <DropdownItem onClick={handleStatusCardClick} disabled={connections && connections.length === 0 || user.role_id == rolesIds.operator}>Configurar estados</DropdownItem>
                  <DropdownItem onClick={handleViewStatusCardClick}>Ver estados</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <CardTitle className="mb-0 d-flex justify-content-between align-items-center">
                <div className='d-flex align-items-center'>
                  <h5 className='mb-0'>ESTADO</h5>
                  <Button
                    onClick={() => {
                      setCurrentTitleHelp('Ayuda de Estados');
                      setCurrentMessageHelp(
                        'Añade estados personalizados para saber cual es el estado actual y visualizar el registro de estados.');
                      toggleHelpModal();
                    }}
                    style={{
                      border: 'none',
                      background: 'none',
                      padding: 0,
                      outline: 'none',
                      boxShadow: 'none'
                    }}
                    className="btn-icon"
                  >
                    <i className="mdi mdi-help-circle text-primary fs-20"></i>
                  </Button>
                </div>
              </CardTitle>


              <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100% - 50px)' }}>
                <div className="float-end">
                </div>
                {renderStatusZone()}
              </div>
            </Card>
          </div>
          <div className="col-md-12 col-lg-10">
            <Card className='px-4 mb-3 mt-0'>
              <CardTitle>
                <div className="d-flex align-items-center">
                  {zoneName ? (
                    <h5 className="mt-1">
                      <NavLink
                        to={`/dashboard/zona/${zoneId}`}
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                        }}
                      >
                        {zoneName}
                      </NavLink>
                    </h5>
                  ) : (
                    <h5 className="mt-1">VARIABLES DEL SISTEMA</h5>
                  )}
                  <Button
                    onClick={() => {
                      setCurrentTitleHelp('Ayuda de variables del sistema');
                      setCurrentMessageHelp(
                        'En las variables del sistema podrás ver las variables calculadas que has creado para esta zona.\n\nPuedes modificarlas de manera personalizada o eliminarlas.'
                      );

                      toggleHelpModal();
                    }}
                    style={{
                      border: 'none',
                      background: 'none',
                      padding: 0,
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    className="btn-icon"
                  >
                    <i className="mdi mdi-help-circle text-primary fs-20"></i>
                  </Button>
                </div>
              </CardTitle>
              <Row className="custom-row">
                {(Array.from({ length: 12 })).map((_, index) => {
                  const newIndex = (index % 6) * 2 + Math.floor(index / 6);
                  const item = calculatedParameters.find((cp: any) => cp.position === newIndex + 1);



                  return (
                    <>
                      <Col key={`mobile-${index}`} xs="12" md="6" lg="2" className="d-block d-lg-none">
                        {(() => {
                          const currentParam = calculatedParameters.find((cp: any) => cp.position === index + 1);
                          const hasConnections = connections && connections.length > 0;

                          return (
                            <Card
                              onClick={() => handleCardClick(currentParam || null, index)}
                              style={{
                                transition: 'transform 0.3s ease',
                                cursor: hasConnections ? 'pointer' : 'not-allowed',
                                minHeight: '45px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                backgroundColor: hasConnections ? '' : '#F5F5F5'
                              }}
                              onMouseEnter={(e) => e.currentTarget.style.transform = hasConnections ? 'translateY(5px)' : 'none'}
                              onMouseLeave={(e) => e.currentTarget.style.transform = hasConnections ? 'translateY(0)' : 'none'}
                            >
                              <div className="d-flex align-items-center justify-content-center" style={{ height: '100%', width: '100%' }}>
                                <div className="text-center">
                                  {(loadedCalculatedParameters || devices.length > 0) ? (
                                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-0">
                                      {currentParam?.name || `VARIABLE ${index + 1}`}
                                    </p>
                                  ) : (
                                    <Spinner size="sm" color="primary" />
                                  )}
                                  {currentParam?.result !== undefined && currentParam?.result !== "" ? (
                                    <h4 className="mb-0">
                                      {!isNaN(Number(currentParam.result))
                                        ? `${parseFloat(String(currentParam.result)).toFixed(1)} ${currentParam.unit}`
                                        : `0 ${currentParam.unit}`}
                                    </h4>
                                  ) : (
                                    currentParam?.name && loadedCalculatedParameters && loadedParametersBackup && <Spinner size="sm" color="primary" />
                                  )}
                                </div>
                              </div>
                            </Card>
                          );
                        })()}
                      </Col>

                      {/* Tarjeta para resto de pantallas (newIndex) */}
                      <Col key={`desktop-${index}`} xs="12" md="6" lg="2" className="d-none d-lg-block">
                        <Card
                          onClick={() => handleCardClick(item || null, newIndex)}
                          style={{
                            transition: 'transform 0.3s ease',
                            cursor: connections && connections.length === 0 ? 'not-allowed' : 'pointer',
                            minHeight: '45px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            backgroundColor: connections && connections.length === 0 ? '#F5F5F5' : ''
                          }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = connections && connections.length === 0 ? 'none' : 'translateY(5px)'}
                          onMouseLeave={(e) => e.currentTarget.style.transform = connections && connections.length === 0 ? 'none' : 'translateY(0)'}
                        >
                          <div className="d-flex align-items-center justify-content-center" style={{ height: '100%', width: '100%' }}>
                            <div className="text-center">
                              {((calculatedParameters && loadedCalculatedParameters) || devices.length > 0) ? (
                                <p className="text-uppercase fw-semibold fs-12 text-muted mb-0">
                                  {item ? item.name : `VARIABLE ${newIndex + 1}`}
                                </p>
                              ) : (
                                <Spinner size="sm" color="primary" />
                              )}
                              {item && item.result !== "" && item.result != null ? (
                                <h4 className="mb-0">
                                  {!isNaN(Number(item.result)) ? `${parseFloat(String(item.result)).toFixed(1)} ${item.unit}` : `0 ${item.unit}`}
                                </h4>
                              ) : (
                                item && item.name && loadedCalculatedParameters && loadedParametersBackup && <Spinner size="sm" color="primary" />
                              )}
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </>
                  );

                })}
              </Row>
            </Card>
          </div>

        </div>
      )}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static">
          <ModalHeader toggle={toggleModal}>{selectedItem ? `Modificar ${selectedItem.name}` : 'Añade nueva variable calculada'}</ModalHeader>
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleConfirm}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }: any) => (
              <Form>
                <ModalBody>
                  <FormGroup row>
                    <Label for="name" sm={3}>Nombre * </Label>
                    <Col sm={9}>
                      <Field name="name" as={Input} type="text" className='rounded-pill' maxLength={18} />
                      <ErrorMessage name="name" component="div" className="text-danger" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="unit" sm={3}>Unidad</Label>
                    <Col sm={9}>
                      <Field name="unit">
                        {({ field, form }: any) => (
                          <Select
                            id="unit"
                            name="unit"
                            options={units}
                            value={field.value ? { value: field.value, label: field.value } : { value: '', label: 'Ninguno' }}
                            onChange={(selectedOption: any) => form.setFieldValue(field.name, selectedOption.value)}
                            isClearable={true}
                            styles={customStyles(10)}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="unit" component="div" className="text-danger" />
                    </Col>
                  </FormGroup>
                  <Col md={12}>
                    <FormGroup>
                      <Row className='align-items-center'>
                        <Col xs="auto">
                          <Label for="in_graph">Gráfica</Label>
                        </Col>
                        <Col>
                          <div className="form-check form-switch form-switch-lg">
                            <Field name="in_graph" type="checkbox" className="form-check-input" id="in_graph" />
                          </div>
                        </Col>
                        <Col xs="auto">
                          <Label for="register">Registro</Label>
                        </Col>
                        <Col>
                          <div className="form-check form-switch form-switch-lg">
                            <Field name="register" type="checkbox" className="form-check-input" id="register" />
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((idx, index, arr) => (
                    <React.Fragment key={idx}>
                      {(idx === 1 || values[`operator_${idx - 1}`]) && (
                        <Card style={{ backgroundColor: 'lightgray', padding: '10px', marginBottom: '10px', display: values[`operator_${idx - 1}`] === 'ninguno' ? 'none' : 'block' }}>
                          <FormGroup row>
                            <Label for={`type_${idx}`} sm={3}>Tipo {idx}</Label>
                            <Col sm={9}>
                              <Select
                                name={`type_${idx}`}
                                options={optionTypeOptions}
                                placeholder="Seleccionar tipo de operador"
                                value={optionTypeOptions.find(option => option.value === values[`type_${idx}` as keyof FormValues]) || null}
                                onChange={(selectedOption: any) => {
                                  setFieldValue(`type_${idx}`, selectedOption ? selectedOption.value : null);
                                }}
                                isSearchable={false}
                                styles={customStyles(10)}
                              />
                              <ErrorMessage name={`type_${idx}`} component="div" className="text-danger" />
                            </Col>
                          </FormGroup>
                          {values[`type_${idx}` as keyof FormValues] === 'Parameter' && (
                            <>
                              <FormGroup row>
                                <Label for={`connection_id_${idx}`} sm={3}>Conexión {idx}</Label>
                                <Col sm={9}>
                                  <Select
                                    name={`connection_id_${idx}`}
                                    options={connections.map(connection => ({
                                      value: connection.id,
                                      label: connection.name
                                    }))}
                                    placeholder="Seleccionar un dispositivo"
                                    value={(() => {
                                      const currentId = values[`connection_id_${idx}` as keyof FormValues];
                                      const connection = connections.find(conn => conn.id === currentId);
                                      return connection ? { value: connection.id, label: connection.name } : null;
                                    })()}
                                    onChange={async (selectedOption: any) => {
                                      const selectedValue = selectedOption ? selectedOption.value : null;
                                      setFieldValue(`connection_id_${idx}`, selectedValue);
                                      setPendingConnection(selectedValue);
                                      if (!parametersBackup[selectedValue]) {
                                        setLoadingParameters(true);
                                        /* await dispatch(getParametersBackup({ connectionId: selectedValue, parameterValues: parameterValues[selectedValue] })); */
                                        setLoadingParameters(false);
                                      } else {
                                        const selectedConnection = parametersBackup[selectedValue];
                                        const filteredParameters = (selectedConnection?.parameters || []).filter((param: any) => param.parameter_type === 'Visualization');
                                        setParameters(filteredParameters);
                                      }
                                    }}
                                    isSearchable={false}
                                    styles={customStyles(10)}
                                  />
                                  <ErrorMessage name={`connection_id_${idx}`} component="div" className="text-danger" />
                                </Col>
                              </FormGroup>

                              <FormGroup row>
                                <Label for={`value_${idx}`} sm={3}>Parámetro {idx}</Label>
                                <Col sm={9}>
                                  {loadingParameters && pendingConnection === values[`connection_id_${idx}` as keyof FormValues] ? (
                                    <Spinner size="sm" color="primary" />
                                  ) : (
                                    <Select
                                      name={`value_${idx}`}
                                      options={parameters.map((param: any) => ({
                                        value: param.id,
                                        label: `${param.index} - ${param.variable} - ID ${param.parameter_id}`
                                      }))}
                                      placeholder="Seleccionar parámetro"
                                      value={(() => {
                                        const currentParamId = values[`value_${idx}` as keyof FormValues];
                                        const parameter = parameters.find((param: any) => Number(param.id) == Number(currentParamId));
                                        return parameter ? { value: parameter.id, label: `${parameter.index} - ${parameter.variable} - ID ${parameter.parameter_id}` } : null;
                                      })()}
                                      onChange={(selectedOption: any) => {
                                        const selectedValue = selectedOption ? selectedOption.value : null;
                                        setFieldValue(`value_${idx}`, selectedValue);
                                      }}
                                      isSearchable={false}
                                      styles={customStyles(10)}
                                    />
                                  )}
                                  <ErrorMessage name={`value_${idx}`} component="div" className="text-danger" />
                                </Col>
                              </FormGroup>
                            </>
                          )}
                          {values[`type_${idx}` as keyof FormValues] === 'Favorite' && (
                            <>
                              <FormGroup row>
                                <Label for={`connection_id_${idx}`} sm={3}>Conexión {idx}</Label>
                                <Col sm={9}>
                                  <Select
                                    name={`connection_id_${idx}`}
                                    options={connections.map(connection => ({
                                      value: connection.id,
                                      label: connection.name
                                    }))}
                                    placeholder="Seleccionar un dispositivo"
                                    value={(() => {
                                      const currentId = values[`connection_id_${idx}` as keyof FormValues];
                                      const connection = connections.find(conn => conn.id === currentId);
                                      return connection ? { value: connection.id, label: connection.name } : null;
                                    })()}
                                    onChange={(selectedOption: any) => {
                                      setFieldValue(`connection_id_${idx}`, selectedOption ? selectedOption.value : null);
                                      setPendingConnection(selectedOption ? selectedOption.value : null);
                                      setLoadingFavorites(true);
                                      dispatch(getFavoritesByConnectionId(selectedOption.value)).then(() => {
                                        setLoadingFavorites(false);
                                      });
                                    }}
                                    isSearchable={false}
                                    styles={customStyles(10)}
                                  />
                                  <ErrorMessage name={`connection_id_${idx}`} component="div" className="text-danger" />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label for={`value_${idx}`} sm={3}>Favorito {idx}</Label>
                                <Col sm={9}>
                                  {loadingFavorites ? (
                                    <Spinner size="sm" color="primary" />
                                  ) : (
                                    <Select
                                      name={`value_${idx}`}
                                      options={(favorites[values[`connection_id_${idx}` as keyof FormValues]] || []).filter((favorite: any) => favorite.type === 'Visualization').map((favorite: any) => ({
                                        value: favorite.id,
                                        label: favorite.name
                                      }))}
                                      placeholder="Seleccionar favorito"
                                      value={(() => {
                                        const currentFavoriteId = values[`value_${idx}` as keyof FormValues];
                                        const favoriteList = favorites[values[`connection_id_${idx}` as keyof FormValues]] || [];
                                        const favorite = favoriteList.find((fav: any) => fav.id == currentFavoriteId);
                                        return favorite ? { value: favorite.id, label: favorite.name } : null;
                                      })()}
                                      onChange={(selectedOption: any) => setFieldValue(`value_${idx}`, selectedOption ? selectedOption.value : null)}
                                      isSearchable={false}
                                      styles={customStyles(10)}
                                    />
                                  )}
                                  <ErrorMessage name={`value_${idx}`} component="div" className="text-danger" />
                                </Col>
                              </FormGroup>
                            </>
                          )}

                          {values[`type_${idx}`] === 'calculatedParameter' && (
                            <>
                              <FormGroup row>
                                <Label for={`value_${idx}`} sm={3}>Variable Calculada {idx}</Label>
                                <Col sm={9}>
                                  <Select
                                    name={`value_${idx}`}
                                    options={calculatedParameters
                                      .filter((calculatedParam: any) => calculatedParam.id !== selectedItem?.id)
                                      .map((calculatedParam: any) => ({
                                        value: calculatedParam.id,
                                        label: calculatedParam.name
                                      }))
                                    }
                                    placeholder="Seleccionar variable calculada"
                                    value={(() => {
                                      const currentCalculatedParamId = values[`value_${idx}`];
                                      const calculatedParam = calculatedParameters.find((param: any) => param.id == currentCalculatedParamId);
                                      return calculatedParam ? { value: calculatedParam.id, label: calculatedParam.name } : null;
                                    })()}
                                    onChange={(selectedOption: any) => setFieldValue(`value_${idx}`, selectedOption ? selectedOption.value : null)}
                                    isSearchable={false}
                                    styles={customStyles(10)}
                                  />
                                  <ErrorMessage name={`value_${idx}`} component="div" className="text-danger" />
                                </Col>
                              </FormGroup>
                            </>
                          )}

                          {values[`type_${idx}` as keyof FormValues] === 'Constant' && (
                            <>
                              <FormGroup row>
                                <Label for={`value_${idx}`} sm={3}>Valor {idx}</Label>
                                <Col sm={9}>
                                  <Field className='rounded-pill' name={`value_${idx}`} as={Input} type="number" />
                                  <ErrorMessage name={`value_${idx}`} component="div" className="text-danger" />
                                </Col>
                              </FormGroup>
                            </>
                          )}
                          <FormGroup row>
                            <Label for={`operator_${idx}`} sm={3}>Operador {idx}</Label>
                            <Col sm={9}>
                              <Select
                                name={`operator_${idx}`}
                                options={operatorOptions}
                                placeholder="Seleccionar operación"
                                value={operatorOptions.find(option => option.value === values[`operator_${idx}` as keyof FormValues]) || null}
                                onChange={(selectedOption: any) => {
                                  if (selectedOption && selectedOption.value === 'ninguno') {
                                    resetFields(setFieldValue, idx);
                                  }
                                  setFieldValue(`operator_${idx}`, selectedOption ? selectedOption.value : null);
                                }}
                                isSearchable={false}
                                styles={customStyles(10)}
                              />
                              <ErrorMessage name={`operator_${idx}`} component="div" className="text-danger" />
                            </Col>
                          </FormGroup>
                        </Card>
                      )}
                    </React.Fragment>
                  ))}
                </ModalBody>
                <ModalFooter className={`d-flex justify-content-center`}>
                  {selectedItem && (
                    <Button className='rounded-pill mr-5' color="danger" onClick={toggleDeleteModal}>
                      <i className="mdi mdi-delete fs-6 "></i>
                      Eliminar
                    </Button>
                  )}
                  <Button className='rounded-pill' color="secondary" onClick={toggleModal}>Cancelar</Button>
                  <Button className='rounded-pill' color="primary" type="submit">Guardar cambios</Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
      {isDeleteModalOpen && (
        <ModalConfirm
          isOpen={isDeleteModalOpen}
          toggle={toggleDeleteModal}
          title="Confirmar Eliminación"
          message={`¿Estás seguro de que quieres eliminar el parámetro calculado ${selectedItem?.name}?`}
          onConfirm={handleDelete}
          onCancel={toggleDeleteModal}
          requireNameConfirmation={true}
          confirmationName={selectedItem?.name || ''}
          idSelected={selectedItem && selectedItem.id}
          iconName='mdi mdi-delete display-5 text-danger'
        />
      )}
      <ModalStatusZone isOpen={isStatusModalOpen} toggle={toggleStatusModal} idSelected={currentZone} zoneId={zoneId} />
      <ModalViewStartupStatus isOpen={isViewStatusModalOpen} toggle={toggleViewStatusModal} idSelected={currentZone} />
      <ModalHelp
        isOpen={isHelpModalOpen}
        toggle={toggleHelpModal}
        title={currentTitleHelp}
        body={currentMessageHelp}
        buttonText="Cerrar"
      />
    </React.Fragment>
  );
};

export default GeneralData;
