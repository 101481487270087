import { createSlice } from "@reduxjs/toolkit";
import {
  getInstallationCalculatedParameters,
  createInstallationCalculatedParameter,
  updateInstallationCalculatedParameter,
  deleteInstallationCalculatedParameter
} from "./thunk";



export const initialState: any = {
  installationCalculatedParameters: {},
  loadedInstallationCalculatedParameters: false,
  valuesOfMultiplesIps: []
};

const applyOperation = (currentValue: number, newValue: number, operator: string | null) => {
  switch (operator) {
    case '+':
      return currentValue + newValue;
    case '-':
      return currentValue - newValue;
    case '*':
      return currentValue * newValue;
    case '/':
      return currentValue / newValue;
    default:
      return parseFloat(newValue.toFixed(2));
  }
};

const convertValueToNumber = (value: string | number) => {
  if (typeof value === 'string') {
    return parseFloat(value.replace(',', '.'));
  }
  return value;
};

const installationCalculatedParametersSlice = createSlice({
  name: "installationCalculatedParameters",
  initialState,
  reducers: {

    setInstallationCalculatedParameters: (state, action) => {
      // Establece los installationCalculatedParameters con el payload recibido
      state.installationCalculatedParameters = action.payload;
    },
    updateInstallationCalculatedParameters: (state, action) => {
      const { allCalculatedParams, installationId } = action.payload;
    
      const calculatedParameters = allCalculatedParams;
    
      Object.keys(state.installationCalculatedParameters).forEach((installationId) => {
        state.installationCalculatedParameters[installationId] = state.installationCalculatedParameters[installationId].map((installationParam: any) => {
          let result = 0;
          let first = true;
    
          for (let i = 1; i <= 10; i++) {
            const typeKey = `type_${i}`;
            const valueKey = `value_${i}`;
            const operatorKey: any = i === 1 ? null : `operator_${i - 1}`;
    
            if (installationParam[typeKey] === 'Constant' && installationParam[valueKey] !== null) {
              const newValue = convertValueToNumber(installationParam[valueKey]);
    
              if (first) {
                result = parseFloat(newValue.toFixed(2));
                first = false;
              } else {
                result = applyOperation(result, newValue, installationParam[operatorKey]);
                result = parseFloat(result.toFixed(2));
              }
            } else if (installationParam[typeKey] === 'calculatedParameter' && installationParam[valueKey] !== null) {
              const calculatedParam = calculatedParameters.find((param: any) => param.id === Number(installationParam[valueKey]));
    
              if (!calculatedParam || calculatedParam.result === "") {
                return { ...installationParam };
              }

              let newValue = convertValueToNumber(calculatedParam.result);
              
              if (isNaN(newValue)) {
                newValue = 0;
            }
              if (first) {
                result = parseFloat(newValue.toFixed(2));
                first = false;
              } else {
                result = applyOperation(result, newValue, installationParam[operatorKey]);
                result = parseFloat(result.toFixed(2));
              }
              console.log("result power", result);
            }
          }
    
          return {
            ...installationParam,
            result,
          };
        });
      });
    },

    
    
 /*    setValuesOfMultiplesIps: (state, action) => {
      const newValues = action.payload.values;


      newValues.forEach((newValue: any) => {
        const existingIndex = state.valuesOfMultiplesIps.findIndex(
          (item: any) =>
            item.parameter_id === newValue.parameter_id &&
            item.bit_parameter_id === newValue.bit_parameter_id &&
            item.connection_id === newValue.connection_id
        );

        if (existingIndex !== -1) {
          // Si el valor es diferente, lo actualizamos
          if (state.valuesOfMultiplesIps[existingIndex].value !== newValue.value) {
            state.valuesOfMultiplesIps[existingIndex] = {
              ...state.valuesOfMultiplesIps[existingIndex],
              ...newValue
            };
          }
        } else {
          // Añadir nuevo valor si no se encuentra
          state.valuesOfMultiplesIps.push(newValue);
        }
      });
    }, */

    /* updateCalculatedParameters: (state, action) => {
      const { parametersBackup, favorites } = action.payload;
      const valuesOfMultiplesIps = state.valuesOfMultiplesIps;
      

      if (parametersBackup.length !== 0 && favorites && valuesOfMultiplesIps) {
        Object.keys(state.calculatedParameters).forEach(zoneId => {
          state.calculatedParameters[zoneId] = state.calculatedParameters[zoneId].map((calcParam: any) => {
            let result = 0;
            let first = true;

            for (let i = 1; i <= 5; i++) {
              const typeKey = `type_${i}`;
              const connectionIdKey = `connection_id_${i}`;
              const valueKey = `value_${i}`;
              let operatorKey = `operator_${i}`;

              if (i != 1) {
                operatorKey = `operator_${i - 1}`;
              }

              if (calcParam[typeKey] === 'Parameter' && calcParam[connectionIdKey] !== null && calcParam[valueKey] !== null) {
                const connectionId = calcParam[connectionIdKey];
                const connectionBackup = parametersBackup[connectionId];

                if (connectionBackup) {
                  const parameter = connectionBackup.parameters.find((param: any) => param.id == calcParam[valueKey]);

                  if (parameter) {
                    const parameterId = parameter.parameter_id;
                    const dataValue = valuesOfMultiplesIps.find(
                      (val: any) =>
                        val.parameter_id == Number(parameterId) &&
                        val.connection_id == connectionId &&
                        val.bit_parameter_id == calcParam.bit_parameter_id
                    );

                    if (dataValue) {
                      const newValue = convertValueToNumber(dataValue.value);

                      if (first) {
                        result = parseFloat(newValue.toFixed(2));
                        first = false;
                      } else {
                        result = applyOperation(result, newValue, calcParam[operatorKey]);
                        result = parseFloat(result.toFixed(2));
                      }
                    }
                  }
                }
              } else if (calcParam[typeKey] === 'Favorite' && calcParam[connectionIdKey] !== null && calcParam[valueKey] !== null) {
                const connectionId = calcParam[connectionIdKey];
                const favoriteList = favorites[connectionId];
                const connectionBackup = parametersBackup[connectionId];

                if (favoriteList) {
                  const favorite = favoriteList.find((fav: any) => fav.id == calcParam[valueKey] && fav.connection_id == connectionId);

                  if (favorite && connectionBackup) {
                    const parameter = connectionBackup.parameters.find((param: any) => param.id == favorite.parameter_id);

                    if (parameter) {
                      const dataValue = valuesOfMultiplesIps.find(
                        (val: any) =>
                          val.parameter_id == Number(parameter.parameter_id) &&
                          val.connection_id == connectionId &&
                          val.bit_parameter_id == calcParam.bit_parameter_id
                      );

                      if (dataValue) {
                        const newValue = convertValueToNumber(dataValue.value);

                        if (first) {
                          result = parseFloat(newValue.toFixed(2));
                          first = false;
                        } else {
                          result = applyOperation(result, newValue, calcParam[operatorKey]);
                          result = parseFloat(result.toFixed(2));
                        }
                      }
                    }
                  }
                }
              } else if (calcParam[typeKey] === 'Constant' && calcParam[valueKey] !== null) {
                const newValue = convertValueToNumber(calcParam[valueKey]);

                if (first) {
                  result = parseFloat(newValue.toFixed(2));
                  first = false;
                } else {
                  result = applyOperation(result, newValue, calcParam[operatorKey]);
                  result = parseFloat(result.toFixed(2));
                }
              } else if (calcParam[typeKey] === 'calculatedParameter' && calcParam[valueKey] !== null) {
                const calculatedParam = state.calculatedParameters[zoneId].find(
                  (param: any) => param.id == calcParam[valueKey]
                );

                if (calculatedParam && calculatedParam.result !== null) {
                  const newValue = convertValueToNumber(calculatedParam.result);

                  if (first) {
                    result = parseFloat(newValue.toFixed(2));
                    first = false;
                  } else {
                    result = applyOperation(result, newValue, calcParam[operatorKey]);
                    result = parseFloat(result.toFixed(2));
                  }
                }
              }
            }

            return {
              ...calcParam,
              result,
            };
          });
        });
      }
    }, */


/*     setCalculatedParametersLoaded: (state, action) => {
      state.loadedCalculatedParameters = action.payload;
    }, */
/*     deleteCalculatedParameters: (state, action) => {
      const connectionId = action.payload;
      state.calculatedParameters = state.calculatedParameters.filter((calcParam: any) => {
        for (let i = 1; i <= 5; i++) {
          if (calcParam[`connection_id_${i}`] === connectionId) {
            return false;
          }
        }
        return true;
      });
    }, */
  },
  extraReducers: (builder) => {

    builder.addCase(getInstallationCalculatedParameters.fulfilled, (state, action: any) => {

     const groupInstallationCalculatedParameters = (parameters: any[]) => {

      if (!Array.isArray(parameters)) {
        return {};
      }

      return parameters.reduce((grouped: Record<number, any[]>, param: any) => {
        const installationId = param.installation_id;

        if (!grouped[installationId]) {
          grouped[installationId] = [];
        }

        grouped[installationId].push(param);
        return grouped;
      }, {});
    };

    const groupedInstallationCalculatedParameters = groupInstallationCalculatedParameters(action.payload);

       state.installationCalculatedParameters = {
        ...state.installationCalculatedParameters,
        ...groupedInstallationCalculatedParameters,
      };

      Object.keys(state.installationCalculatedParameters).forEach((installationId) => {
        if (!Array.isArray(state.installationCalculatedParameters[installationId])) {
          state.installationCalculatedParameters[installationId] = [];
        }
      });
    });

    builder.addCase(createInstallationCalculatedParameter.fulfilled, (state, action) => {
      const newParameter = action.payload;
      const installationId = newParameter.installation_id;


      if (!state.installationCalculatedParameters[installationId]) {
        state.installationCalculatedParameters[installationId] = [];
      }
      state.installationCalculatedParameters[installationId].push(newParameter);
    });

    builder.addCase(updateInstallationCalculatedParameter.fulfilled, (state, action) => {
      const updatedParameter = action.payload;
      const installationId = updatedParameter.installation_id;

      if (state.installationCalculatedParameters[installationId]) {
        const index = state.installationCalculatedParameters[installationId].findIndex((param: any) => param.id === updatedParameter.id);
        if (index !== -1) {

          state.installationCalculatedParameters[installationId][index] = updatedParameter;
        }
      }
    });

    builder.addCase(deleteInstallationCalculatedParameter.fulfilled, (state, action: any) => {
      const { id, installationId } = action.payload;
      if (state.installationCalculatedParameters[installationId]) {
        state.installationCalculatedParameters[installationId] = state.installationCalculatedParameters[installationId].filter(
          (installationCalculatedParameter: any) => installationCalculatedParameter.id !== id
        );
      }
    });
  },
});
/* export const { updateCalculatedParameters, setCalculatedParametersLoaded, setValuesOfMultiplesIps, deleteCalculatedParameters } = calculatedParametersSlice.actions; */
export const { setInstallationCalculatedParameters, updateInstallationCalculatedParameters } = installationCalculatedParametersSlice.actions;
export default installationCalculatedParametersSlice.reducer;