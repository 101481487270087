import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  Card,
  CardBody,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  CardTitle,
} from 'reactstrap';
import LinkedParamsTooltip from 'Components/Common/Tooltips/LinkedParamsTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { reorderMainVariables } from 'slices/mainVariables/thunk';
import { createSelector } from 'reselect';
import ModalHelp from 'Components/Common/Modals/ModalHelp';
import MainVariablesBySection from './MainVariablesBySection';
import { useParams } from 'react-router-dom';
import { getSectionDataConfigByZone } from 'slices/thunks';
import { match } from 'assert';
import { set } from 'lodash';

type FavoriteItem = {
  id: string;
  name: string;
  unit: string;
  value?: string;
  isInChart: boolean;
  color: string;
  tooltipOpen?: boolean;
  connection_id: number;
  favorite_id: number;
  favorite_parameter_id: number;
};

type MainVariable = {
  id: number;
  name: string;
  unit: string;
  value?: string;
  favorite_id: number;
  position: number;
  color: string;
  connection_id: number;
  connection_name: string;
  favorite_parameter_id: number;
  parameter_index: string;
};

type Props = {
  mainVariables: MainVariable[];
  className: string;
  maxItems: number;
  SynopticCardId: number;
  sectionId: number;
};

const SynopticCard: React.FC<Props> = ({ mainVariables, className, maxItems, SynopticCardId, sectionId }) => {
  const [favorites, setFavorites] = useState<FavoriteItem[]>([]);
  const [tooltipState, setTooltipState] = useState<{ [key: string]: boolean }>({});
  const dispatch: any = useDispatch();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedFavorite, setSelectedFavorite] = useState<FavoriteItem | null>(null);
  const [connections, setConnections] = useState<any[]>([]);
  const [matchMainVariables, setMatchMainVariables] = useState<any[]>([]);
  const [sectionName, setSectionName] = useState<string>('');
  const { id } = useParams();

  const toggleHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);
  const toggleModal = () => setModal(!modal);

  const selectParameterValuesState = (state: any) => state.ParameterValues;
  const selectInstallationsState = (state: any) => state.Installations;
  const selectSynopticImageConfigState = (state: any) => state.SectionDataConfig;

  const parameterValuesDataProperties = createSelector(
    selectParameterValuesState,
    (state) => state
  );

  const installationsDataProperties = createSelector(
    selectInstallationsState,
    (state) => state
  );

  const sectionConfigDataProperties = createSelector(
    selectSynopticImageConfigState,
    (state) => state
  );


  const { parameterValues } = useSelector(parameterValuesDataProperties);
  const { installations } = useSelector(installationsDataProperties);
  const { sectionDataConfig } = useSelector(sectionConfigDataProperties)

  useEffect(() => {
    dispatch(getSectionDataConfigByZone(Number(id)))
  }, [])

  useEffect(() => {
    if (mainVariables && sectionDataConfig) {
      const sectionConfigs: any = sectionDataConfig[Number(id)] || {};
      const matchedMainVariables: any = mainVariables
        .map((mv: any) => {
          const config: any = Object.values(sectionConfigs).find((config: any) =>
            config.main_variable_id === mv.id && config.section_id == sectionId
          );
          return config ? { ...mv, position: config.position } : null;
        })
        .filter((mv: any) => mv !== null)
        .sort((a: any, b: any) => a.position - b.position); // Ordenar según la propiedad 'position'

      setMatchMainVariables(matchedMainVariables);
    } else {
      setMatchMainVariables([]);
    }
  }, [mainVariables, sectionDataConfig, id, sectionId]);



  useEffect(() => {
    if (installations && installations.length > 0 && Array.isArray(installations)) {
      const matchedZones = installations.flatMap((installation: any) =>
        installation.zones.filter((zone: any) => zone.id === Number(id))
      );
      if (matchedZones.length > 0) {
        setConnections(matchedZones[0].connections);
      }
    }
  }, [id, installations]);


  const selectStyles = {
    control: (base: any, state: any) => ({
      ...base,
      borderRadius: '20px',
      borderColor: state.isFocused ? '#80bdff' : base.borderColor,
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#80bdff' : '#ced4da'
      }
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: '10px<'
    }),
  };

  useEffect(() => {
    const initialFavorites = matchMainVariables
      .slice(0, maxItems)
      .map((mv) => ({
        id: mv.id.toString(),
        name: `${mv.name}`,
        unit: mv.unit,
        value: mv.value,
        isInChart: true,
        color: mv.color,
        connection_id: mv.connection_id,
        tooltipOpen: favorites.find((fav) => fav.id === mv.id.toString())?.tooltipOpen || false,
        favorite_id: mv.favorite_id,
        favorite_parameter_id: mv.favorite_parameter_id,
      }));
    setFavorites(initialFavorites);
  }, [mainVariables, maxItems, matchMainVariables]);

  const toggleFavoriteTooltip = (id: string) => {
    setTooltipState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedFavorite) {
      const { name, value, checked, type } = event.target;
      setSelectedFavorite({
        ...selectedFavorite,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const selectFavorite = (favorite: FavoriteItem) => {
    setSelectedFavorite(favorite);
    toggleModal();
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(favorites);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFavorites(items);
    const orderedIds = items.map((item) => Number(item.id));
    dispatch(reorderMainVariables({ reorderedItems: orderedIds }));
  };


  return (
    <>
      <Card className={`mh-100 mb-0 ${className}`}>
        <CardTitle className="bg-light mb-0 p-md-1 text-center d-flex align-items-center card-title">
          <div className="d-flex align-items-center justify-content-center rounded-circle bg-primary text-white circle fw-bold circle">
            {SynopticCardId}
          </div>
          {sectionDataConfig && sectionDataConfig[Number(id)] && sectionDataConfig[Number(id)].find((section: any) => Number(section.section_id) === Number(sectionId))?.name}
        </CardTitle>
        <CardBody
          className="m-0 mt-1"
          style={{ maxHeight: '800px', overflowY: 'auto', paddingTop: '0px', paddingBottom: '0px' }}
        >

          <UncontrolledDropdown
            direction="start"
            style={{ position: 'absolute', top: '0px', right: '10px' }}
          >
            <DropdownToggle className="text-reset" tag="a" role="button">
              <span className="text-muted fs-18">
                <i className="mdi mdi-dots-horizontal"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-end">
              <DropdownItem onClick={toggleModal} >
                Configurar
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {favorites.length === 0 ? (
            <div className="text-center my-5">
              <i className="mdi mdi-star-off-outline fs-2" />
              <h5 className="text-muted">No hay favoritos asociados</h5>
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-synoptic">
                {(provided: any) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {favorites.map((favorite, index) => (
                      <Draggable key={favorite.id} draggableId={favorite.id} index={index} isDragDisabled={true}>
                        {(provided: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Row
                              className="bg-light mb-1 position-relative "
                              id={`favorite-${favorite.id}`}
                              onClick={() => toggleFavoriteTooltip(favorite.id)}
                            >
                              <div
                                className="favorite-row"
                                style={{
                                  backgroundColor: favorite.color,
                                  borderRadius: '4px',
                                  width: '100%',
                                }}
                              >
                                <span
                                  className="m-0 text-truncate"
                                  style={{ flex: '0 1 75%' }}
                                >
                                  {favorite.name}
                                </span>
                                <span
                                  className="m-0 text-center"
                                  style={{
                                    flex: '0 1 25%',
                                    minWidth: '44.5%',
                                    marginLeft: 'auto',
                                  }}
                                >
                                  {favorite.value !== undefined && favorite.value !== null && favorite.value !== "" ? (
                                    <>
                                      <span className="favorite-value">
                                        {
                                          parameterValues[favorite.connection_id]?.[
                                            favorite.favorite_parameter_id
                                          ]?.find(
                                            (p: any) => p.value === favorite.value
                                          )?.textValue ?? favorite.value
                                        }
                                      </span>
                                      <span className="favorite-unit ms-2">
                                        {favorite.unit}
                                      </span>
                                    </>
                                  ) : (
                                    <Spinner size="sm" color="primary" />
                                  )}
                                </span>
                              </div>

                            </Row>
                            {tooltipState[favorite.id] && (
                              <LinkedParamsTooltip
                                target={`favorite-${favorite.id}`}
                                isOpen={!!tooltipState[favorite.id]}
                                toggle={() => toggleFavoriteTooltip(favorite.id)}
                                connection_id={favorite.connection_id}
                                favorite_id={favorite.favorite_id}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </CardBody>
      </Card>
      <MainVariablesBySection
        mainVariables={mainVariables}
        isOpen={modal}
        toggle={toggleModal}
        connections={connections}
      />
      <ModalHelp
        isOpen={isHelpModalOpen}
        toggle={toggleHelpModal}
        title="Ayuda de gráfica a tiempo real"
        body="Visualiza las variables que necesites en la gráfica a tiempo real habilitándola desde un favorito de visualización."
        buttonText="Cerrar"
      />
    </>
  );
};

export default SynopticCard;
