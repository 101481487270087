import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import ModalAddDevice from 'Components/Common/Modals/ModalAddDevice';
import { getItemStyle, getListStyle } from '.';
import { reorderConnections } from 'slices/thunks';

interface InitialDevices {
    initialDevices: any;
    props: any;
    zoneId: number;
    installationId: number;
}

const DraggableDevices = ({ initialDevices, props, zoneId, installationId }: InitialDevices) => {
    const [devices, setDevices] = useState(initialDevices);
    const [modalKey, setModalKey] = useState<string | null>(null);
    const [modal, setModal] = useState(false);
    const dispatch: any = useDispatch();

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result: any) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const connections = devices
            .filter((item: any) => item.id !== 'createConnection')
            .flatMap((item: any) => {
                if (item.childItems) {
                    return item.childItems.map(({
                        bid,
                        configured_connection,
                        created_at,
                        created_by,
                        deleted_at,
                        device_id,
                        id,
                        img,
                        installation_id,
                        ip,
                        is_active,
                        label: name,
                        sn,
                        supervisor_id,
                        updated_at,
                        zone_id



                    }: any) => ({
                        bid,
                        configured_connection,
                        created_at,
                        created_by,
                        deleted_at,
                        device_id,
                        id,
                        img,
                        installation_id,
                        ip,
                        is_active,
                        name,
                        sn,
                        supervisor_id,
                        updated_at,
                        zone_id
                    }));
                }
                return [];
            });


        const reorderedConnections = reorder(
            connections,
            source.index,
            destination.index
        );

        const payload = {
            connections: reorderedConnections,
            installationId,
            zoneId
        };

        dispatch(reorderConnections(payload));

        const updatedDevices = devices.map((device: any) => {
            if (device.id === 'devices_' + zoneId) {
                return {
                    ...device,
                    childItems: reorder(device.childItems, source.index, destination.index)
                };
            }
            return device;
        });

        setDevices(updatedDevices);
    };

    useEffect(() => {

        setDevices(initialDevices);
    }, [initialDevices]);

    const showModal = (key: string) => {
        setModalKey(key);
        toggle();
    };

    const toggle = () => {
        setModal(!modal);
    };

    return (
        <>
            <ul className="nav nav-sm flex-column">
                {devices.map((device: any, index: any) => (
                    <li key={device.id} className="nav-item">
                        {!device.childItems ? (
                            <NavLink
                                to={device.link || '/#'}
                                className={({ isActive, isPending }) =>
                                    `${isPending ? 'pending' : ''} ${isActive && device.id !== 'createConnection' ? 'active' : ''} nav-link`
                                }
                                onClick={device.id === 'createConnection' ? () => showModal(device.id) : undefined}
                            >
                                {device.label}
                            </NavLink>
                        ) : (
                            <React.Fragment>
                                <NavLink
                                    to="/#"
                                    className={({ isActive, isPending }) =>
                                        `${isPending ? 'pending' : ''} ${isActive ? 'active' : ''} nav-link`
                                    }
                                    onClick={device.click}
                                    data-bs-toggle="collapse"
                                    aria-expanded={device.stateVariables}
                                >
                                    {device.label}
                                </NavLink>
                                <Collapse isOpen={device.stateVariables} className="menu-dropdown">
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId={`droppable-nav-${zoneId}`}>
                                            {(provided: any, snapshot: any) => (
                                                <ul
                                                    className="nav nav-sm flex-column"
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {device.childItems.map((subDevice: any, key: any) => (
                                                        <Draggable key={subDevice.id} draggableId={subDevice.id.toString()} index={key}>
                                                            {(provided: any, snapshot: any) => (
                                                                <li
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                    className="nav-item"
                                                                >
                                                                    <NavLink
                                                                        to={subDevice.link}
                                                                        onClick={subDevice.id === 'createConnection' ? () => showModal(subDevice.id) : undefined}
                                                                        className={({ isActive, isPending }) =>
                                                                            `${isPending ? 'pending' : ''} ${isActive ? 'active' : ''} nav-link`
                                                                        }
                                                                    >
                                                                        {subDevice.label}
                                                                    </NavLink>
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </ul>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Collapse>
                            </React.Fragment>
                        )}
                    </li>
                ))}
            </ul>
            {modalKey === 'createConnection' && modal ? <ModalAddDevice isOpen={modal} toggle={toggle} zoneId={zoneId} /> : null}
        </>
    );
};

export default DraggableDevices;
