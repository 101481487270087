export const units = [
  { value: '', label: 'Ninguna' },
  { value: '%', label: '%' },
  { value: 'A', label: 'A' },
  { value: '1/min', label: '1/min' },
  { value: 'rpm', label: 'rpm' },
  { value: 'ppm', label: 'ppm' },
  { value: 'pps', label: 'pps' },
  { value: 'l/s', label: 'l/s' },
  { value: 'l/min', label: 'l/min' },
  { value: 'l/h', label: 'l/h' },
  { value: 'kg/s', label: 'kg/s' },
  { value: 'kg/min', label: 'kg/min' },
  { value: 'kg/h', label: 'kg/h' },
  { value: 'm³/s', label: 'm³/s' },
  { value: 'm³/min', label: 'm³/min' },
  { value: 'm³/h', label: 'm³/h' },
  { value: 'm³', label: 'm³' },
  { value: 'm/s', label: 'm/s' },
  { value: 'mbar', label: 'mbar' },
  { value: 'bar', label: 'bar' },
  { value: 'Pa', label: 'Pa' },
  { value: 'kPa', label: 'kPa' },
  { value: 'mVS', label: 'mVS' },
  { value: 'kW', label: 'kW' },
  { value: 'kW/m³', label: 'kW/m³' },
  { value: 'kWh', label: 'kWh' },
  { value: 'MWh', label: ' MWh' },
  { value: '°C', label: '°C' }, 
  { value: '°F', label: '°F' }, 
  { value: 'gal/s', label: 'gal/s' },
  { value: 'gal/min', label: 'gal/min' },
  { value: 'gal/h', label: 'gal/h' },
  { value: 'lb/s', label: 'lb/s' },
  { value: 'lb/min', label: 'lb/min' },
  { value: 'lb/h', label: 'lb/h' },
  { value: 'ft³/s', label: 'ft³/s' },
  { value: 'ft³/min', label: 'ft³/min' },
  { value: 'ft³/h', label: 'ft³/h' },
  { value: 'ft/s', label: 'ft/s' },
  { value: 'in wg', label: 'in wg' },
  { value: 'ft wg', label: 'ft wg' },
  { value: 'PSI', label: 'PSI' },
  { value: 'lb/in²', label: 'lb/in²' },
  { value: 'cv', label: 'cv' },
  { value: 'pie', label: 'pie' },
  { value: 'pulgada', label: 'pulgada' },
  { value: 'mm', label: 'mm' },
  { value: 'cm', label: 'cm' },
  { value: 'm', label: 'm' },
  { value: 'gpm', label: 'gpm' },
  { value: 'CFM', label: 'CFM' },
  { value: 'Hz', label: 'Hz' }
];
