import { createSlice } from "@reduxjs/toolkit";
import {
  getCalculatedParametersByZone,
  createCalculatedParameter,
  updateCalculatedParameter,
  deleteCalculatedParameter
} from "./thunk";




export const initialState: any = {
  calculatedParameters: [],
  loadedCalculatedParameters: false,
  valuesOfMultiplesIps: []
};

const applyOperation = (currentValue: number, newValue: number, operator: string | null) => {
  switch (operator) {
    case '+':
      return currentValue + newValue;
    case '-':
      return currentValue - newValue;
    case '*':
      return currentValue * newValue;
    case '/':
      return currentValue / newValue;
    default:
      return parseFloat(newValue.toFixed(2));
  }
};

const convertValueToNumber = (value: string | number) => {
  if (typeof value === 'string') {
    return parseFloat(value.replace(',', '.'));
  }
  return value;
};

const calculatedParametersSlice = createSlice({
  name: "calculatedParameters",
  initialState,
  reducers: {

    setValuesOfMultiplesIps: (state, action) => {
      const newValues = action.payload;

      newValues && newValues.forEach((newValue: any) => {
        const existingIndex = state.valuesOfMultiplesIps.findIndex(
          (item: any) =>
            item.parameter_id === newValue.parameter_id &&
            item.bit_parameter_id === newValue.bit_parameter_id &&
            item.connection_id === newValue.connection_id
        );

        if (existingIndex !== -1) {
          // Si el valor es diferente, lo actualizamos
          if (state.valuesOfMultiplesIps[existingIndex].value !== newValue.value) {
            state.valuesOfMultiplesIps[existingIndex] = {
              ...state.valuesOfMultiplesIps[existingIndex],
              ...newValue
            };
          }
        } else {
          // Añadir nuevo valor si no se encuentra
          state.valuesOfMultiplesIps.push(newValue);
        }
      });
    },

    updateCalculatedParameters: (state, action) => {
      const { parametersBackup, favorites } = action.payload;
      const valuesOfMultiplesIps = state.valuesOfMultiplesIps;


      if (parametersBackup.length !== 0 && favorites && valuesOfMultiplesIps) {
        Object.keys(state.calculatedParameters).forEach(zoneId => {
          state.calculatedParameters[zoneId] = state.calculatedParameters[zoneId].map((calcParam: any) => {
            let result = 0;
            let first = true;

            const countDecimals = (parameterDetails: any) => {
              const normalizeDecimals = (value: string) => {
                return value.replace(',', '.');
              };

              const minDecimals = parameterDetails && parameterDetails.min
                ? (normalizeDecimals(parameterDetails.min).split(".")[1] || '').length
                : 0;
              const maxDecimals = parameterDetails && parameterDetails.max
                ? (normalizeDecimals(parameterDetails.max).split(".")[1] || '').length
                : 0;
              return Math.max(minDecimals, maxDecimals);
            };

            const normalizeValue = (value: any, decimals: any) => {
              const factor = Math.pow(10, decimals);
              return (value / factor).toFixed(decimals);
            };

            for (let i = 1; i <= 10; i++) {
              const typeKey = `type_${i}`;
              const connectionIdKey = `connection_id_${i}`;
              const valueKey = `value_${i}`;
              let operatorKey = `operator_${i}`;

              if (i != 1) {
                operatorKey = `operator_${i - 1}`;
              }

              if (calcParam[typeKey] === 'Parameter' && calcParam[connectionIdKey] !== null && calcParam[valueKey] !== null) {
                const connectionId = calcParam[connectionIdKey];
                const connectionBackup = parametersBackup[connectionId];

                if (connectionBackup) {
                  const parameter = connectionBackup.parameters.find((param: any) => param.id == calcParam[valueKey]);

                  if (parameter) {
                    const parameterId = parameter.parameter_id;
                    const dataValue = valuesOfMultiplesIps.find(
                      (val: any) =>
                        val.parameter_id == Number(parameterId) &&
                        val.connection_id == connectionId &&
                        val.bit_parameter_id == calcParam.bit_parameter_id
                    );

                    if (dataValue === null || dataValue && dataValue.value === null || dataValue && dataValue.value === ''){
                      console.warn("El valor aún está vacío, se interrumpe el cálculo.");
                      return calcParam; // Retornar sin modificar el cálculo
                    }

                    if (dataValue) {

                      let decimals;

                      if (connectionId == 74 && parameterId == 73) {
                        console.log("dataValue.value", dataValue.value)
                        if (dataValue.value.toString().length === 3) {
                          decimals = 1;
                        }

                        if (dataValue.value.toString().length === 2) {
                          decimals = 0;
                        }

                      } else {
                        decimals = countDecimals(parameter);
                      }

                      const newValue = normalizeValue(dataValue.value, decimals);

                      if (first) {
                        result = Number(newValue);
                        first = false;
                      } else {
                        result = applyOperation(result, Number(newValue), calcParam[operatorKey]);
                      }
                    }
                  }
                }
              } else if (calcParam[typeKey] === 'Favorite' && calcParam[connectionIdKey] !== null && calcParam[valueKey] !== null) {
                const connectionId = calcParam[connectionIdKey];
                const favoriteList = favorites[connectionId];
                const connectionBackup = parametersBackup[connectionId];

                if (favoriteList) {
                  const favorite = favoriteList.find((fav: any) => fav.id == calcParam[valueKey] && fav.connection_id == connectionId);

                  if (favorite && connectionBackup) {
                    const parameter = connectionBackup.parameters.find((param: any) => param.id == favorite.parameter_id);

                    if (parameter) {
                      const dataValue = valuesOfMultiplesIps.find(
                        (val: any) =>
                          val.parameter_id == Number(parameter.parameter_id) &&
                          val.connection_id == connectionId &&
                          val.bit_parameter_id == calcParam.bit_parameter_id
                      );

                      if (dataValue === null || dataValue && dataValue.value === null || dataValue && dataValue.value === ''){
                        console.warn("El valor aún está vacío, se interrumpe el cálculo.");
                        return calcParam; // Retornar sin modificar el cálculo
                      }

                      if (dataValue) {
                        const rawValue = convertValueToNumber(dataValue.value);
                        let newValue;
                        
                        // Check if max_scale exists and apply it if necessary
                        if (favorite.max_scale) {
                    
                          newValue = (rawValue * parseFloat(favorite.max_scale)) / 100;
                      

                        } else {
                          newValue = rawValue;

                        }

                        // Calculate decimal precision based on the parameter's min and max settings
                        const countDecimals = (value: string) => {
                          if (!value) return 0;
                          const normalizedValue = value.replace(',', '.');  // Normalize the decimal separator
                          const parts = normalizedValue.split('.');  // Split the integer and decimal parts
                          return parts.length > 1 ? parts[1].length : 0;  // Count the number of decimal places
                        };

                        const minDecimals = countDecimals(parameter.min);

                        const maxDecimals = countDecimals(parameter.max);
                        const maxDecimalPlaces = Math.max(minDecimals, maxDecimals);

                        newValue = normalizeValue(newValue, maxDecimalPlaces);



                        if (first) {
                          result = Number(newValue);

                          first = false;
                        } else {
                          result = applyOperation(result, Number(newValue), calcParam[operatorKey]);

                        }

                        result = parseFloat(result.toFixed(maxDecimalPlaces));
                      }
                    }
                  }
                }
              } else if (calcParam[typeKey] === 'Constant' && calcParam[valueKey] !== null) {
                const newValue = convertValueToNumber(calcParam[valueKey]);

                if (first) {
                  result = parseFloat(newValue.toFixed(2));
                  first = false;
                } else {
                  result = applyOperation(result, newValue, calcParam[operatorKey]);
                  result = parseFloat(result.toFixed(2));
                }
              } else if (calcParam[typeKey] === 'calculatedParameter' && calcParam[valueKey] !== null) {
                const calculatedParam = state.calculatedParameters[zoneId].find(
                  (param: any) => param.id == calcParam[valueKey]
                );

                if (calculatedParam && calculatedParam.result !== null) {
                  
                  const newValue = convertValueToNumber(calculatedParam.result);

                  if (first) {
                    result = parseFloat(newValue.toFixed(2));
                    first = false;
                  } else {
                    result = applyOperation(result, newValue, calcParam[operatorKey]);
                    result = parseFloat(result.toFixed(2));
                  }
                }
              }
            }

            return {
              ...calcParam,
              result,
            };
          });
        });
      }
    },


    setCalculatedParametersLoaded: (state, action) => {
      state.loadedCalculatedParameters = action.payload;
    },
    deleteCalculatedParameters: (state, action) => {
      const connectionId = action.payload;
      state.calculatedParameters = state.calculatedParameters.filter((calcParam: any) => {
        for (let i = 1; i <= 10; i++) {
          if (calcParam[`connection_id_${i}`] === connectionId) {
            return false;
          }
        }
        return true;
      });
    },
  },
  extraReducers: (builder) => {

    builder.addCase(getCalculatedParametersByZone.fulfilled, (state, action: any) => {
      const groupCalculatedParametersByZone = (parameters: any[]) => {
        return parameters.reduce((grouped: Record<number, any[]>, param: any) => {
          const zoneId = param.installation_zone_id;

          // Si aún no existe la zona en grouped, la inicializamos con un array vacío
          if (!grouped[zoneId]) {
            grouped[zoneId] = [];
          }

          // Añadimos el parámetro al array correspondiente
          grouped[zoneId].push(param);
          return grouped;
        }, {});
      };

      const groupedParameters = groupCalculatedParametersByZone(action.payload);

      // Asegurarse de que cada zone_id en el estado tenga un array (incluso si está vacío)
      state.calculatedParameters = {
        ...state.calculatedParameters,
        ...groupedParameters,
      };

      // Asegura que todas las zone_id's estén representadas con un array vacío si no tienen datos
      Object.keys(state.calculatedParameters).forEach((zoneId) => {
        if (!Array.isArray(state.calculatedParameters[zoneId])) {
          state.calculatedParameters[zoneId] = [];
        }
      });
    });

    builder.addCase(getCalculatedParametersByZone.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


    builder.addCase(createCalculatedParameter.fulfilled, (state, action) => {
      const newParameter = action.payload;
      const zoneId = newParameter.installation_zone_id;

      // Inicializa el array para esta zona si aún no existe
      if (!state.calculatedParameters[zoneId]) {
        state.calculatedParameters[zoneId] = [];
      }

      // Añade el nuevo parámetro al array de la zona correspondiente
      state.calculatedParameters[zoneId].push(newParameter);
    });

    builder.addCase(createCalculatedParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateCalculatedParameter.fulfilled, (state, action) => {
      const updatedParameter = action.payload;
      const zoneId = updatedParameter.installation_zone_id;

      // Verifica si existe el grupo de la zona y busca el índice del parámetro a actualizar
      if (state.calculatedParameters[zoneId]) {
        const index = state.calculatedParameters[zoneId].findIndex((param: any) => param.id === updatedParameter.id);
        if (index !== -1) {
          // Actualiza el parámetro en el índice encontrado con los nuevos valores
          state.calculatedParameters[zoneId][index] = updatedParameter;
        }
      }
    });

    builder.addCase(updateCalculatedParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


    builder.addCase(deleteCalculatedParameter.fulfilled, (state, action: any) => {
      const { id, zoneID } = action.payload;
      if (state.calculatedParameters[zoneID]) {
        state.calculatedParameters[zoneID] = state.calculatedParameters[zoneID].filter(
          (calculatedParameter: any) => calculatedParameter.id !== id
        );
      }
    });

    builder.addCase(deleteCalculatedParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});
export const { updateCalculatedParameters, setCalculatedParametersLoaded, setValuesOfMultiplesIps, deleteCalculatedParameters } = calculatedParametersSlice.actions;
export default calculatedParametersSlice.reducer;